import { Formik, Form, Field, ErrorMessage } from "formik";
import HeaderDescriptive from "src/components/HeaderDescriptive";
import PageHeader from "src/components/PageHeader";
import FormikField from "src/components/FormikField/FormikField";
import { useNavigate } from "react-router-dom";
import { FiUserPlus } from "react-icons/fi";
import { useEffect, useState } from "react";
import { get, post } from "src/services/HttpClient";
import { notify } from "src/services/Notification/Notification";
import useGetCurrentURL from "src/utils/useGetCurrentURL";
import TwinButton from "src/components/TwinButton";

export default function AddUser() {
    const roleOptions = [
        // { value: 1, label: 'سوپر ادمین' },
        { value: 2, label: 'مدیر پروژه' },
        { value: 3, label: 'ناظر پروژه' },
        { value: 4, label: 'کارشناس خبره' },
        { value: 5, label: 'تحلیل‌گر شغل' }
    ]
    const [orgOptions, setOrgOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const pathname = useGetCurrentURL();

    const getOrgs = async () => {
        let result = await get("/organizations");
        if (result.body) {
            setOrgOptions(result.body.map(function (single) {
                return {
                    value: single.id,
                    label: single.name
                }
            }))
        }
    }
    useEffect(() => {
        getOrgs();
    }, [])

    return (
        <>
            <PageHeader title="افزودن کاربر جدید" />
            <Formik
                initialValues={{
                    fullName: "",
                    email: "",
                    password: "",
                    roleId: "",
                    organizationId: ""
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setIsLoading(true)
                    const result = await post("/register", {
                        fullName: values.fullName,
                        email: values.email,
                        password: values.password,
                        roleId: values.roleId,
                        organizationId: values.organizationId || null
                    });
                    if (result) {
                        notify("کاربر با موفقیت به سیستم اضافه شد")
                        navigate(pathname.slice(0, pathname.search("/add")));
                    } else {
                        notify("خطایی رخ داده است", "error")
                    }
                    setIsLoading(false)
                }}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <div className="flex flex-col bg-white dark:bg-black rounded-2xl p-10 px-20">
                            <HeaderDescriptive
                                title="اطلاعات کاربر"
                                description="اطلاعات زیر را برای اضافه شدن کاربر تکمیل نمایید"
                            >
                                <FiUserPlus size={40} />
                            </HeaderDescriptive>
                            <div className="flex gap-10 mt-10 ">
                                <div className="flex flex-col xl:grid gap-5 gap-y-9 grid-cols-2  w-full justify-center">
                                    <FormikField
                                        inputName="fullName"
                                        label="نام"
                                        placeholder="نام کامل کاربر را وارد کنید..."
                                        // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                        errors={errors}
                                    />
                                    <FormikField
                                        inputName="email"
                                        label="ایمیل"
                                        placeholder="ایمیل کاربر را وارد کنید..."
                                        // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                        errors={errors}
                                    />
                                    <FormikField
                                        inputName="password"
                                        label="گذرواژه"
                                        placeholder="گذرواژه کاربر را وارد کنید..."
                                        // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                        errors={errors}
                                    />
                                    <FormikField
                                        inputName="roleId"
                                        type="select"
                                        label="سطح دسترسی"
                                        placeholder="سطح دسترسی کاربر را وارد کنید..."
                                        errors={errors}
                                        options={roleOptions}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                    />
                                    {
                                        values.roleId != 1 && values.roleId
                                        &&
                                        <FormikField
                                            inputName="organizationId"
                                            type="select"
                                            label="سازمان"
                                            placeholder="سازمان کاربر را وارد کنید..."
                                            errors={errors}
                                            options={orgOptions}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                    }


                                </div>
                            </div>
                            <TwinButton
                                rejectHandle={() => {
                                    navigate(pathname.slice(0, pathname.search("/add")));
                                }}
                                isLoading={isLoading}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
