import { useContext, useEffect, useState } from "react";
import IconInfoBox from "src/components/IconInfoBox";
import QuickAccessBox from "src/components/QuickAccessBox";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BiTask } from "react-icons/bi";

import userContext from "src/context/userContext";
import { getData } from "src/actions/getData";
export default function Dashboard() {
    const { user } = useContext(userContext)

    const [data, setData] = useState();

    useEffect(() => {
      getData(setData,"/analytics")
    }, [])


    
    return (
        <>
            {
                user?.role?.role == "superAdmin"
                &&
                <div className="flex flex-col">
                    <div className="grid gap-5 grid-cols-1 xl:grid-cols-3">
                        <IconInfoBox
                            title={<div className="flex gap-2 items-center">{data?.jobCount}<span className="text-sm text-gray-400 font-normal">شغل</span></div>}
                            upTitle="تعداد کل مشاغل"
                            icon={<BiTask size={40} className="text-[#3e70c2]" />}
                        >
                        </IconInfoBox>
                        <IconInfoBox
                            title={<div className="flex gap-2 items-center">{data?.userCount}<span className="text-sm text-gray-400 font-normal">کاربر</span></div>}
                            upTitle="تعداد کاربران"
                            icon={<HiOutlineUserGroup size={40} className="text-[#3e70c2]" />}
                        />
                        <IconInfoBox
                            title={<div className="flex gap-2 items-center">{data?.organizationCount}<span className="text-sm text-gray-400 font-normal">سازمان</span></div>}
                            upTitle="تعداد سازمان‌ها"
                            icon={<HiOutlineBuildingOffice2 size={40} className="text-[#3e70c2]" />}
                        />
                    </div>
                    <QuickAccessBox
                        title="تعریف سازمان"
                        subTitle="تعریف پروژه جدید"
                        to="/app/organization-management/organization"
                    />
                </div >
            }
            {
                user?.role?.role == "projectManager"
                &&
                <>
                    <QuickAccessBox
                        title="تعریف کاربران"
                        subTitle="تعریف کاربران پروژه"
                        to="/app/user-management/user"
                    />
                    <QuickAccessBox
                        title="مدیریت شغل‌ها"
                        subTitle="مشاهده لیست مشاغل"
                        to="/app/job-management/job"
                    />
                </>

            }
            {
                user?.role?.role == "projectSupervisor" || user?.role?.role == "jobAnalystExpert" || user?.role?.role == "jobAnalyst"
                &&
                <QuickAccessBox
                    title="تحلیل شغل"
                    subTitle="مشاهده لیست مشاغل"
                    to="/app/job-management/job"
                />
            }
        </>
    )
}