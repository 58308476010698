import React from 'react'
import PageHeader from 'src/components/PageHeader'
import JobTable from './JobTable';


export default function Job() {
    let token = localStorage.getItem("access_token")
    return (
        <div>
            <PageHeader
                title="لیست مشاغل"
                // btnTitle="تحلیل شغل جدید"
                // handleClick={() => {
                //     window.open(`${process.env.REACT_APP_DATA_ENTRY_URL}?t=${token}`)
                // }}
                // permission="NOT_SUPER_USER_PERMISSIONS"
            />
            <JobTable />
        </div>
    )
}
