import { useEffect, useState } from "react";
import { LuX } from "react-icons/lu";
import { LuCheck } from "react-icons/lu";
import PageHeader from "src/components/PageHeader";
import { get, post } from "src/services/HttpClient";
import { notify } from "src/services/Notification/Notification";

export const rolesTranslate = {
  "superAdmin": "سوپر ادمین",
  "projectManager": "مدیر پروژه",
  "projectSupervisor": "ناظر پروژه",
  "jobAnalystExpert": "کارشناس خبره",
  "jobAnalyst": "تحلیل‌گر شغل",
}

function Roles() {

  const getRoles = async () => {
    const result = await get("/roles");
    setAllRoles(result.body.map(function (single) {
      return {
        role: single.role,
        permissions: JSON.parse(single.permissions)
      }
    }))
  }

  useEffect(() => {
    getRoles();
  }, [])

  const [allRoles, setAllRoles] = useState([]);

  const permissions = [
    "viewDashboard",
    "createNewUser",
    "viewUserList",
    "editUserPermission",
    "editUser",
    "deleteUser",
    "createNewOrganization",
    "viewOrganizationList",
    "editOrganization",
    "deleteOrganization",
    "viewJobList",
    "createNewJobAnalysis",
    "editJobAnalysis",
    "viewReportsList",
  ];

  const permissionsTranslate = [
    "نقش",
    "مشاهده داشبورد",
    "ایجاد کاربر جدید",
    "مشاهده لیست کاربران",
    "ویرایش سطح دسترسی",
    "ویرایش کاربر",
    "حذف کاربر",
    "ایجاد سازمان",
    "مشاهده لیست سازمان‌ها",
    "ویرایش سازمان",
    "حذف سازمان",
    "مشاهده لیست مشاغل",
    "تحلیل شغل جدید",
    "ویرایش تایید تحلیل شغل",
    "مشاهده گزارش‌ها",
  ];



  const handleUpdateAccess = (index, key) => {
    // super Admin roles can't change
    if (index == 0) {
      return;
    }
    // others can
    setAllRoles((prevRoles) => {
      const updatedRoles = [...prevRoles];
      const role = updatedRoles[index];

      if (role?.permissions?.includes(key)) {
        role.permissions = role.permissions.filter((access) => access !== key);
      } else {
        role.permissions = [...role.permissions, key];
      }
      return updatedRoles;
    });
  };

  const submitChanges = async () => {
    let result = await post("/roles", allRoles)
    if (result.success) {
      notify("تمام نقش‌ها با موفقیت به روز رسانی شدند")
    } else {
      notify("خطایی رخ داده است", "error")
    }
  }

  return (
    <>
      <PageHeader
        title="نقش های سیستم"
      />
      <div className="flex flex-col bg-white dark:bg-black rounded-2xl p-6">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 border">
            <thead>
              <tr>
                {permissionsTranslate.map((x) => {
                  return (
                    <th className="px-1.5 py-3 border border-white text-bold bg-gray-800  text-center text-xs leading-4   text-white  tracking-wider">
                      {x}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {allRoles.map((i, index) => (
                <tr
                  classNameName={`${index % 2 ? "border-gray-100" : "bg-white bg-opacity-50"
                    }`}
                >
                  <td className="select-none px-1.5 py-4 text-bold text-center text-xs  border border-gray-100">
                    {rolesTranslate[i.role]}
                  </td>
                  {permissions.map((x) => {
                    return (
                      <td
                        className="px-1.5   border border-gray-100 cursor-pointer py-4 text-bold text-xs"
                        onClick={() => handleUpdateAccess(index, x)}
                      >
                        <span className="flex items-center justify-center">
                          {i?.permissions?.includes(x) || i.permissions == "SUPER_USER_PERMISSIONS" ? (
                            <LuCheck
                              size={24}
                              style={{ color: "#34d399" }}
                            />
                          ) : (
                            <LuX
                              size={24}
                              style={{ color: "#fb7185" }}
                            />
                          )}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-row justify-end items-end mt-8">
          <button
            className=" transition-all text-white text-sm bg-primary-color px-6 py-2 rounded-md"
            onClick={submitChanges}
          >
            ثبت تغییرات
          </button>
        </div>
      </div>
    </>
  );
}

export default Roles;
