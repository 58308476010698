import PageHeader from 'src/components/PageHeader'
import { useNavigate } from "react-router-dom";
import UserTable from './UserTable';
import useGetCurrentURL from 'src/utils/useGetCurrentURL';


export default function User() {
    const navigate = useNavigate();
    const pathname = useGetCurrentURL();

    return (
        <div>
            <PageHeader
                title="لیست کاربران"
                btnTitle="افزودن کاربر جدید"
                handleClick={() => {
                    navigate(pathname + "/add");
                }}
                permission="createNewUser"
            />
            <UserTable />
        </div>
    )
}
