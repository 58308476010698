import { PiCodesandboxLogoDuotone } from "react-icons/pi";

export default function IconInfoBox({
  title,
  upTitle,
  coloredInfo,
  children,
  icon,
  onClick = () => { },
}) {
  return (
    <>
      <div
        onClick={onClick}
        className="w-full flex gap-5 flex-col-reverse xl:flex-row p-6 pb-8 bg-white dark:bg-black border border-solid border-gray-200 rounded-2xl "
        style={{ cursor: "pointer" }}
      >
        <div className="w-full flex flex-[3] flex-col gap-4">
          <span>{upTitle}</span>
          <span className="font-semibold text-xl">{title}</span>
          {!children && coloredInfo && (
            <div>
              <span className="bg-blue-50 p-2 py-1 rounded-xl text-blue-500">
                {coloredInfo}
              </span>
            </div>
          )}
          {children}
        </div>
        <div className="w-full flex flex-1 justify-start xl:justify-end">
          <div className="inline-flex justify-center items-start">
            <div className="flex items-start bg-blue-50 p-4 rounded-xl dark:bg-gray-900">
              {icon}
              {/* <PiCodesandboxLogoDuotone size={40} className="text-[#3e70c2]" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
