import { useContext, useEffect, useState } from "react";
import Table from "src/components/Table";
import { PiEyeDuotone } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { get, post } from "src/services/HttpClient";
import { IoTrashOutline } from "react-icons/io5";

import PermissionBasedComponent from "src/components/PermissionBasedComponent";
import DeleteModal from "src/components/DeleteModal";
import userContext from "src/context/userContext";
import { notify } from "src/services/Notification/Notification";
import Loading from "src/components/Loading";

export default function OrganizationTable() {

    const navigate = useNavigate();

    const { user } = useContext(userContext)
    const [data, setData] = useState()
    const [active, setActive] = useState(false)
    const [reload, setReload] = useState(false)
    const [selectedRow, setSelectedRow] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const getOrganizations = async () => {
        const result = await get("/organizations");
        setData(result.body)
    }
    useEffect(() => {
        getOrganizations();
    }, [reload])

    const switchToOrganizationView = async (organizationId) => {
        setIsLoading(true)
        let result = await post("/users/", {
            organizationId: organizationId,
            roleId: 2
        })
        if (result && result.success) {
            window.location.href = "/"
        } else {
            notify("خطایی رخ داده است لطفا مجددا امتحان کنید", "error")
        }
        setIsLoading(false)
    }



    const columns = [
        {
            id: "id",
            cell: (info) => info.getValue(),
            header: "کد",
            footer: (info) => info.column.id,
        },
        {
            id: "name",
            cell: (info) => info.getValue(),
            header: <span>نام</span>,
            footer: (info) => info.column.id,
        },
        {
            id: "logo",
            cell: (info) => <div className="flex">
                <img className="w-16" src={process.env.REACT_APP_API_URL + "/img/" + info.getValue()} />
            </div>,
            header: "لوگو",
            footer: (info) => info.column.id,
        },
        {
            id: "id",
            cell: (info) => (
                <PermissionBasedComponent permission="editOrganization">
                    <div className="flex gap-2">
                        <div
                            className="cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg"
                            onClick={() => switchToOrganizationView(info.getValue())}
                        >
                            <div className="flex justify-center text-center whitespace-nowrap">
                                <span>
                                    ورود به پنل
                                </span>
                            </div>
                        </div>
                        <div
                            className="cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg"
                            onClick={() => {
                                navigate(
                                    `/app/organization-management/organization/edit?id=${info.getValue()}`
                                );
                            }}
                        >
                            <FiEdit className="text-blue-500" />
                        </div>
                        <div
                            className="cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg"
                            onClick={() => {
                                setSelectedRow(info.getValue())
                                setActive(true)
                            }}
                        >
                            <IoTrashOutline className="text-red-500" />
                        </div>
                    </div>
                </PermissionBasedComponent>
            ),
            header: "عملیات",
            footer: (info) => info.column.id,
            enableColumnFilter: false,
        },
    ];


    return (
        <>
            <Table data={data} columns={columns} />
            <DeleteModal
                title="حذف سازمان"
                id={selectedRow}
                url="/organizations"
                active={active}
                setActive={setActive}
                additionalAction={() => { setReload(!reload) }}
            />
            {
                isLoading
                &&
                <Loading title="لطفا منتظر بمانید"/>
            }
        </>
    );
}
