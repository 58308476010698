import React from 'react'
import OrganizationTable from './OrganizationTable'
import PageHeader from 'src/components/PageHeader'
import { useNavigate } from "react-router-dom";
import useGetCurrentURL from 'src/utils/useGetCurrentURL';


export default function Organization() {
    const navigate = useNavigate();
    const pathname = useGetCurrentURL();

    return (
        <div>
            <PageHeader
                title="لیست سازمان‌ها"
                btnTitle="افزودن سازمان جدید"
                handleClick={() => {
                    navigate(pathname + "/add");
                }}
                permission="createNewOrganization"
            />
            <OrganizationTable />
        </div>
    )
}
