import { useContext, useEffect, useState } from "react";
import Table from "src/components/Table";
import { PiEyeDuotone } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
import { get } from "src/services/HttpClient";
import PermissionBasedComponent from "src/components/PermissionBasedComponent";
import userContext from "src/context/userContext";
import DeleteModal from "src/components/DeleteModal";
import { useNavigate } from "react-router-dom";
import { IoTrashOutline } from "react-icons/io5";

export default function UserTable() {
    const navigate = useNavigate();

    const { user } = useContext(userContext)
    const [data, setData] = useState()
    const [active, setActive] = useState(false)
    const [reload, setReload] = useState(false)
    const [selectedRow, setSelectedRow] = useState()

    const getUsers = async () => {
        if (user.organization) {
            const result = await get(`/organizations/${user.organization.id}/users`);
            setData(result.body)
        } else {
            const result = await get("/users");
            setData(result.body)
        }
    }
    useEffect(() => {
        getUsers();
    }, [reload])

    const columns = [
        {
            id: "id",
            cell: (info) => info.getValue(),
            header: "شناسه",
            footer: (info) => info.column.id,
        },
        {
            id: "fullName",
            cell: (info) => info.getValue(),
            header: <span>نام کامل</span>,
            footer: (info) => info.column.id,
        },
        {
            id: "email",
            cell: (info) => info.getValue(),
            header: "ایمیل",
            footer: (info) => info.column.id,
        },
        {
            id: "roleId",
            cell: (info) => info.getValue() == 1 ? "سوپر ادمین" : info.getValue() == 2 ? "مدیر پروژه" : info.getValue() == 3 ? "ناظر پروژه" : info.getValue() == 4 ? "کارشناس خبره" : "تحلیل‌گر شغل",
            header: "نقش کاربری",
            footer: (info) => info.column.id,
        },
        {
            id: "organization",
            cell: (info) => info.getValue() ? info.getValue().name : "*",
            header: "سازمان",
            footer: (info) => info.column.id,
        },
        {
            id: "id",
            cell: (info) => (
                <PermissionBasedComponent permission="editUser">
                    <div className="flex gap-2">
                        <div
                            className="cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg"
                            onClick={() => {
                                navigate(
                                    `/app/user-management/user/edit?id=${info.getValue()}`
                                );
                            }}
                        >
                            <FiEdit className="text-blue-500" />
                        </div>
                        <div
                            className="cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg"
                            onClick={() => {
                                setSelectedRow(info.getValue())
                                setActive(true)
                            }}
                        >
                            <IoTrashOutline className="text-red-500" />
                        </div>
                    </div>
                </PermissionBasedComponent>
            ),
            header: "عملیات",
            footer: (info) => info.column.id,
            enableColumnFilter: false,
        },
    ];


    return (
        <>
            <Table data={data} columns={columns} />
            <DeleteModal
                title="حذف کاربر"
                id={selectedRow}
                url="/users"
                active={active}
                setActive={setActive}
                additionalAction={() => { setReload(!reload) }}
            />
        </>
    );
}   
