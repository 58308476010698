import { Formik, Form, Field, ErrorMessage } from "formik";
import HeaderDescriptive from "src/components/HeaderDescriptive";
import PageHeader from "src/components/PageHeader";
import FormikField from "src/components/FormikField/FormikField";
import { useNavigate, useLocation } from "react-router-dom";
import { BsBuildingAdd } from "react-icons/bs";
import { useEffect, useState } from "react";
import { get, post } from "src/services/HttpClient";
import { notify } from "src/services/Notification/Notification";
import useGetCurrentURL from "src/utils/useGetCurrentURL";

export default function EditOrganization() {

    const [data, setData] = useState({})

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get("id");

    const getOrganizationData = async () => {
        const result = await get(`/organizations/${id}`);
        setData(result.body)
    }
    useEffect(() => {
        getOrganizationData();
    }, [])


    const navigate = useNavigate();
    const pathname = useGetCurrentURL();

    return (
        <>
            <PageHeader title="ویرایش سازمان" />
            <Formik
                enableReinitialize
                initialValues={{
                    name: data?.name,
                    file: data?.logo,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        if (values.file.name) {
                            let formData = new FormData();
                            formData.append('file', values.file);
                            let result = await post("/upload", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            })
                            if (!result) {
                                throw new Error();
                            }
                        }
                        let result = await post(`/organizations/${id}`, {
                            name: values.name,
                            logo: values?.file?.name || undefined
                        })
                        if (!result) {
                            throw new Error();
                        }
                        notify("سازمان با موفقیت بروز شد");
                    } catch (error) {
                        notify("خطایی رخ داده است", "error")
                    }
                }}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <div className="flex flex-col bg-white dark:bg-black rounded-2xl p-10 px-20">
                            <HeaderDescriptive
                                title="اطلاعات سازمان"
                                description="اطلاعات زیر را برای اضافه شدن سازمان تکمیل نمایید"
                            >
                                <BsBuildingAdd size={40} />
                            </HeaderDescriptive>
                            <div className="flex gap-10 mt-10 ">
                                <div className="flex flex-col xl:grid gap-5 gap-y-9 grid-cols-2  w-full justify-center">
                                    <FormikField
                                        inputName="name"
                                        label="نام"
                                        placeholder="نام سازمان را وارد کنید..."
                                        // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                        errors={errors}
                                    />
                                    <FormikField
                                        type="file"
                                        values={values}
                                        inputName="file"
                                        label="فایل لوگو"
                                        placeholder={values?.file?.name ? values?.file?.name : "فایل لوگو را در فرمت‌های jpg, png, webp انتخاب کنید"}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-5 items-center justify-center p-4 mt-10 lg:flex lg:flex-row">
                                <button
                                    onClick={() => {
                                        navigate(pathname.slice(0, pathname.search("/edit")));
                                    }}
                                    className="flex justify-center items-center text-sm bg-gray-100 text-gray px-16 py-2 rounded-md xl:px-12 xl:py-3"
                                >
                                    <span>انصراف</span>
                                </button>
                                <button
                                    type="submit"
                                    className="flex justify-center items-center text-sm text-white bg-primary-color px-16 py-2 rounded-md xl:px-12 xl:py-3"
                                >
                                    <span>ذخیره</span>
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik >
        </>
    );
}
