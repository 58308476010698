import { PiUser } from "react-icons/pi";
import { PiKey } from "react-icons/pi";
import { FcLock } from "react-icons/fc";
import { FcScatterPlot } from "react-icons/fc";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { get, post } from "src/services/HttpClient";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { notify } from "src/services/Notification/Notification";
import { useContext, useState } from "react";
import userContext from "src/context/userContext";

export default function Login() {
    const navigate = useNavigate();

    const { setUser } = useContext(userContext)
    const [isLoading, setIsLoading] = useState(false)
    const getUser = async () => {
        const result = await get("/user");
        if (result) {
            setUser(result.body);
        }
    };

    return (
        <Formik
            initialValues={{
                email: "",
                password: ""
            }}
            // validate={values => {
            //     const errors = {};
            //     if (!values.email) {
            //         errors.email = 'Required';
            //     } else if (
            //         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            //     ) {
            //         errors.email = 'Invalid email address';
            //     }
            //     return errors;
            // }}
            onSubmit={async (values, { setSubmitting }) => {
                setIsLoading(true)
                const result = await post("/login", {
                    email: values.email,
                    password: values.password
                });
                if (result) {
                    localStorage.setItem("access_token", result.body.token);
                    axios.defaults.headers.common['Authorization'] = result.body.token;
                    getUser();
                    navigate("/");
                } else {
                    notify("نام کاربری یا گذرواژه اشتباه است", "error")
                }
                setIsLoading(false)
            }}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <div className="flex justify-center items-center h-screen overflow-hidden login-container p-4">
                        <div className="relative bg-[#ffffff83] w-full max-w-[500px] rounded-2xl shadow-al backdrop-blur-[15px] p-6 text-gray-800">
                            <div className="flex flex-col gap-6 my-5">
                                <div className="flex justify-center">
                                    <img src="/logo512.png" className="w-14" />
                                </div>
                                <div className="flex justify-center ">
                                    <span className="font-medium text-lg">
                                        سامانه تحلیل مشاغل
                                    </span>
                                </div>
                                <div className="flex flex-col gap-3">
                                    <div className="gap-2 rounded-full flex items-center w-full px-3 text-gray-700">
                                        <PiUser size={26} className="text-black" />
                                        <Field
                                            type="email"
                                            placeholder="ایمیل"
                                            className="bg-[#eff4ffa5] w-full rounded-full p-3 outline-none"
                                            name="email"
                                        />
                                    </div>
                                    <div className="gap-2 rounded-full flex items-center w-full px-3 text-gray-700">
                                        <PiKey size={26} className="text-black" />
                                        <Field
                                            type="password"
                                            placeholder="گذرواژه"
                                            className="bg-[#eff4ffa5] w-full rounded-full p-3 outline-none"
                                            name="password"
                                        />
                                    </div>
                                    <div className="flex w-full justify-end">
                                        <button className="px-4 text-sm">
                                            فراموشی رمز عبور؟
                                        </button>
                                    </div>
                                    <button type="submit" className="flex justify-center text-center w-full ">
                                        <div className="bg-primary-color flex justify-center text-white rounded-full items-center w-full p-3 mt-3 h-[50px]">
                                            {
                                                isLoading
                                                    ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity="0.25" /><path fill="currentColor" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"><animateTransform attributeName="transform" dur="1.125s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" /></path></svg>
                                                    :
                                                    <span>ورود به حساب کاربری</span>
                                            }
                                        </div>
                                    </button>
                                    <div className="flex gap-2 w-full justify-center text-sm">
                                        <button>
                                            حساب کاربری ندارید؟
                                        </button>
                                        <span className="font-medium text-white">ثبت نام</span>
                                        <span>کنید</span>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute -bottom-[30px] text-white w-full text-center pl-12 text-sm">
                                تمامی حقوق محفوظ است ©
                            </div>
                            <div className="absolute bg-white p-4 rounded-full right-12 -top-[35px] shadow-al">
                                <FcScatterPlot size={30} />
                            </div>
                            <div className="absolute bg-white p-4 rounded-full -left-[35px] top-12 shadow-al">
                                <FcLock size={30} />
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
