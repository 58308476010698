import { useContext, useEffect, useState } from "react"
import Loading from "src/components/Loading";
import userContext from "src/context/userContext"
import { get, post } from "src/services/HttpClient"
import { notify } from "src/services/Notification/Notification";
import { homeURL } from "src/settings/config";

export default function SidebarFooter() {
    const { user } = useContext(userContext)
    const [whiteList, setWhiteList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getWhiteList = async () => {
        let result = await get("/users/white-list")
        if (result && result.body) {
            setWhiteList(result.body);
        }
    }

    useEffect(() => {
        getWhiteList();
    }, [])


    const returnToSuperAdminView = async () => {
        setIsLoading(true)
        let result = await post("/users/", {
            organizationId: null,
            roleId: 1
        })
        if (result && result.success) {
            window.location.href = "/"
        } else {
            notify("خطایی رخ داده است لطفا مجددا امتحان کنید", "error")
        }
        setIsLoading(false)
    }

    return (
        <>
            <div className="flex-shrink-0 p-2 max-h-14">
                {
                    user?.organization && whiteList?.includes(user.email)
                    &&
                    <button
                        className="flex items-center justify-center w-full px-4 py-2 space-x-1 font-medium tracking-wider uppercase bg-gray-100 hover:bg-gray-200 transition-all ease-in-out duration-200 border rounded-md focus:outline-none focus:ring"
                        onClick={returnToSuperAdminView}
                    >
                        <span> بازگشت به داشبورد ادمین </span>
                    </button>
                }
            </div>
            {
                isLoading
                &&
                <Loading title="لطفا منتظر بمانید" />
            }
        </>
    )
}