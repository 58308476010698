export default function Footer() {
    return (
        <footer className="flex items-center justify-between flex-shrink-0 p-4 max-h-14 bg-[#f5f8fa] dark:bg-[#333333]">
            <div> </div>
            <div>
                <span className="text-xs">
                {new Date().getFullYear()} - تمامی حقوق محفوظ است &copy;
                </span>
            </div>
        </footer>
    )
}