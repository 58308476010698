import { useContext } from "react"
import userContext from "src/context/userContext"

export default function SidebarHeader() {
    const { user } = useContext(userContext);
    return (
        <div className="flex items-center justify-between flex-shrink-0 p-2">
            {
                Object.hasOwn(user, 'organization')
                    ?
                    <span className="w-full flex flex-col gap-3 items-center justify-center p-2 text-base font-semibold leading-8 tracking-wider whitespace-nowrap">
                        <img src={user.organization ? process.env.REACT_APP_API_URL + "/img/" + user.organization.logo : `/logo512.png`} className="w-16" />
                        <span>{user.organization ? user.organization.name : "جابونت"}</span>
                        <div className="w-full h-[2px] bg-gray-100"></div>
                    </span>
                    :
                    null
                    // TODO LOADING SKELETON
            }
        </div>
    )
}