import React from 'react'

export default function HeaderDescriptive({ title, description, children, align = "center" }) {
    return (
        <>
            {
                children
                &&
                <div className="flex w-full justify-center mb-4">
                    {children}
                </div>
            }
            <div className={`flex gap-3 flex-col items-${align}`}>
                <span className='text-xl font-medium'>
                    {title}
                </span>
                <p>
                    {description}
                </p>
            </div>
        </>

    )
}
