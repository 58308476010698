import { Formik, Form, Field, ErrorMessage } from "formik";
import HeaderDescriptive from "src/components/HeaderDescriptive";
import PageHeader from "src/components/PageHeader";
import FormikField from "src/components/FormikField/FormikField";
import { useNavigate } from "react-router-dom";
import { BsBuildingAdd } from "react-icons/bs";
import { post } from "src/services/HttpClient";
import { notify } from "src/services/Notification/Notification";
import useGetCurrentURL from "src/utils/useGetCurrentURL";
import TwinButton from "src/components/TwinButton";
import { useState } from "react";

export default function AddOrganization() {

  const navigate = useNavigate();
  const pathname = useGetCurrentURL();

  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <PageHeader title="افزودن سازمان جدید" />
      <Formik
        initialValues={{
          name: "",
          file: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setIsLoading(true)
            if (values.file.name) {
              let formData = new FormData();
              formData.append('file', values.file);

              let result = await post("/upload", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              if (!result) {
                throw new Error();
              }
              result = await post("/organizations", {
                name: values.name,
                logo: values.file.name
              })
              if (!result) {
                throw new Error();
              }
              notify("سازمان با موفقیت به سیستم اضافه شد")
              navigate(pathname.slice(0, pathname.search("/add")));

            }
            setIsLoading(false)
          } catch (error) {
            notify("خطایی رخ داده است", "error")
            setIsLoading(false)
          }
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="flex flex-col bg-white dark:bg-black rounded-2xl p-10 px-20">
              <HeaderDescriptive
                title="اطلاعات سازمان"
                description="اطلاعات زیر را برای اضافه شدن سازمان تکمیل نمایید"
              >
                <BsBuildingAdd size={40} />
              </HeaderDescriptive>
              <div className="flex gap-10 mt-10 ">
                <div className="flex flex-col xl:grid gap-5 gap-y-9 grid-cols-2  w-full justify-center">
                  <FormikField
                    inputName="name"
                    label="نام"
                    placeholder="نام سازمان را وارد کنید..."
                    // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                    errors={errors}
                  />
                  <FormikField
                    type="file"
                    values={values}
                    inputName="file"
                    label="فایل لوگو"
                    placeholder={values.file ? values.file.name : "فایل لوگو را در فرمت‌های jpg, png, webp انتخاب کنید"}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <TwinButton
                rejectHandle={() => {
                  navigate(pathname.slice(0, pathname.search("/add")));
                }}
                isLoading={isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
