import { useContext } from "react";
import { Link } from "react-router-dom";
import userContext from "src/context/userContext";
import { homeURL } from "src/settings/config";

export default function SidebarHeader() {
    const { user } = useContext(userContext)
    return (
        <div className="flex items-center justify-center flex-shrink-0 p-2">
            <Link to={homeURL}>
                <span className="flex flex-col justify-center items-center p-2 text-lg font-medium leading-8 tracking-wider whitespace-nowrap">
                    {/* <img src={user.organization ? process.env.REACT_APP_API_URL + "/img/" + user.organization.logo : `/logo512.png`} className="w-10" /> */}
                    {/* <span>جابونت</span> */}
                </span>
            </Link>
        </div>
    )
}