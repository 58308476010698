import { adminRoot } from "./config";
import { HiOutlineClipboardList, HiOutlineNewspaper } from "react-icons/hi";
import { VscSettings } from "react-icons/vsc";
import { BsDatabase } from "react-icons/bs";
import { LuBrainCircuit, LuLayoutDashboard } from "react-icons/lu";
import { RiHistoryLine } from "react-icons/ri";
import { PiCubeDuotone } from "react-icons/pi";
import { PiTreeStructureDuotone } from "react-icons/pi";
import { AiTwotoneSetting } from "react-icons/ai";
import { PiGearSix } from "react-icons/pi";
import { VscKey } from "react-icons/vsc";
import Dashboard from "src/views/app/dashboard/Dashboard";
import PublicView from "src/views/PublicView";
import Denied from "src/views/user/Denied";
import Login from "src/views/user/Login";
import { Outlet } from "react-router-dom";
import Organization from "src/views/app/organization/Organization";
import Job from "src/views/app/job/Job";
import AddOrganization from "src/views/app/organization/AddOrganization";
import EditOrganization from "src/views/app/organization/EditOrganization";
import Roles from "src/views/app/userRoles/Roles";
import User from "src/views/app/user/User";
import AddUser from "src/views/app/user/AddUser";
import ProtectedView from "src/views/ProtectedView";
import AddJob from "src/views/app/job/AddJob";
import ChangePassword from "src/views/app/Settings/ChangePassword";
import EditJob from "src/views/app/job/EditJob";

const menuArray = [
    {
        path: "",
        element: <ProtectedView />,
        subs: [
            {
                path: "",
                element: <Dashboard />,
            },
        ],
    },
    {
        icon: <VscSettings size={30} />,
        label: "داشبورد",
        path: `${adminRoot}/dashboard`,
        permission: "viewDashboard",
        element: <ProtectedView />,
        inMenu: true,
        subs: [
            {
                icon: <LuLayoutDashboard />,
                label: "خانه",
                path: `${adminRoot}/dashboard`,
                element: <Dashboard />,
                inMenu: true,
                permission: "viewDashboard",
            },
        ],
    },
    {
        icon: <HiOutlineNewspaper size={30} />,
        label: "سازمان‌ها",
        path: `${adminRoot}/organization-management`,
        inMenu: true,
        element: <ProtectedView />,
        permission: "viewOrganizationList",
        subs: [
            {
                icon: <PiCubeDuotone />,
                label: "لیست سازمان‌ها",
                inMenu: true,
                path: `${adminRoot}/organization-management/organization`,
                permission: "viewOrganizationList",
                element: <Outlet />,
                subs: [
                    {
                        icon: <PiCubeDuotone />,
                        inMenu: false,
                        path: `${adminRoot}/organization-management/organization`,
                        permission: "viewOrganizationList",
                        element: <Organization />,
                    },
                    {
                        icon: <PiCubeDuotone />,
                        label: "افزودن سازمان جدید",
                        inMenu: false,
                        path: `${adminRoot}/organization-management/organization/add`,
                        permission: "createNewOrganization",
                        element: <AddOrganization />,
                    },
                    {
                        icon: <PiCubeDuotone />,
                        label: "ویرایش سازمان",
                        inMenu: false,
                        path: `${adminRoot}/organization-management/organization/edit`,
                        permission: "editOrganization",
                        element: <EditOrganization />,
                    },
                ],
            },
        ],
    },
    {
        icon: <BsDatabase size={30} />,
        label: "بانک شناسنامه مشاغل",
        inMenu: true,
        path: `${adminRoot}/job-management`,
        element: <ProtectedView />,
        permission: "viewJobList",
        subs: [
            {
                icon: <HiOutlineClipboardList />,
                label: "لیست مشاغل",
                path: `${adminRoot}/job-management/job`,
                inMenu: true,
                element: <Job />,
                permission: "viewJobList",
            },
            {
                icon: <HiOutlineClipboardList />,
                label: "تعریف شغل جدید",
                path: `${adminRoot}/job-management/job/add`,
                inMenu: true,
                element: <AddJob />,
                permission: "NOT_SUPER_USER_PERMISSIONS",
            },
            {
                icon: <HiOutlineClipboardList />,
                label: "ویرایش شغل",
                path: `${adminRoot}/job-management/job/edit`,
                inMenu: false,
                element: <EditJob />,
                permission: "viewJobList",
            },
        ],
    },
    {
        icon: <RiHistoryLine size={30} />,
        label: "مدیریت دسترسی",
        path: `${adminRoot}/user-management`,
        permission: "viewUserList",
        inMenu: true,
        element: <ProtectedView />,
        subs: [
            {
                icon: <PiCubeDuotone />,
                label: "نقش‌ها",
                path: `${adminRoot}/user-management/permision`,
                inMenu: true,
                element: <Roles />,
                permission: "editUserPermission",
            },
            {
                icon: <PiTreeStructureDuotone />,
                label: "کاربران سیستم",
                inMenu: true,
                element: <Outlet />,
                path: `${adminRoot}/user-management/user`,
                permission: "viewUserList",
                subs: [
                    {
                        icon: <PiCubeDuotone />,
                        label: "کاربران سیستم",
                        inMenu: false,
                        element: <User />,
                        path: `${adminRoot}/user-management/user`,
                        permission: "viewUserList",
                    },
                    {
                        icon: <PiCubeDuotone />,
                        label: "افزودن کاربر جدید",
                        inMenu: false,
                        element: <AddUser />,
                        path: `${adminRoot}/user-management/user/add`,
                        permission: "createNewUser",
                    },
                ],
            },
        ],
    },
    {
        icon: <PiGearSix size={30} />,
        label: "تنظیمات",
        path: `${adminRoot}/setting`,
        inMenu: true,
        element: <ProtectedView />,
        permission: "VIEW_PROFILE",
        subs: [
            {
                icon: <VscKey />,
                label: "تغییر رمز عبور",
                inMenu: true,
                path: `${adminRoot}/setting/change-password`,
                permission: "VIEW_PROFILE",
                element: <ChangePassword />,
            },
        ],
    },
    {
        icon: <LuBrainCircuit size={30} />,
        label: "یادگیری ماشینی",
        path: `${adminRoot}/machine-learning`,
        inMenu: true,
        permission: "SUPER_USER_PERMISSIONS",
        subs: [
            {
                icon: <AiTwotoneSetting />,
                label: "در حال توسعه...",
                inMenu: true,
                path: `${adminRoot}/machine-learning`,
            },
        ],
    },
    {
        path: "/user/",
        element: <Outlet />,
        subs: [
            {
                path: "login/",
                element: <Login />,
            },
            {
                path: "register/",
                element: <Login />,
            },
            {
                path: "denied/",
                element: <Denied />,
            },
        ],
    },
];
export default menuArray;
