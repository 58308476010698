export default function TwinButton({ acceptTitle = "ذخیره تغییرات", rejectTitle = "بازگشت", acceptHandle = () => { }, rejectHandle = () => { }, isCompact = false, isLoading = false }) {
    return (
        <div className={`${isCompact ? "p-0 gap-3 justify-end" : "p-4 gap-5 justify-center mt-10"} w-full items-center flex flex-col lg:flex lg:flex-row`}>
            <button
                type="button"
                onClick={rejectHandle}
                className={`${isCompact ? "w-fit" : "w-[190px]"} flex justify-center items-center text-sm bg-gray-100 hover:bg-gray-200 transition-all ease-in-out duration-200 text-gray px-6 py-2 rounded-md xl:px-8 xl:py-3`}
            >
                <span>{rejectTitle}</span>
            </button>
            <button
                type="submit"
                className={`${isCompact ? "w-fit" : "w-[190px]"} flex justify-center items-center opacity-90 hover:opacity-100 transition-all ease-in-out duration-200 text-sm text-white bg-primary-color px-6 py-2 rounded-md xl:px-8 xl:py-3`}
                onClick={acceptHandle}
            >
                {
                    isLoading
                        ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity="0.25" /><path fill="currentColor" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"><animateTransform attributeName="transform" dur="1.125s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" /></path></svg>
                        :
                        <span>{acceptTitle}</span>
                }
            </button>
        </div>
    )
}
